var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"home_top"},[_c('div',{staticClass:"inner inner1"},[_c('div',{staticClass:"left_item"},[_c('h1',[_vm._v("Get a higher financial altitude!")]),_c('h4',[_vm._v("Start your financial freedom to well-being. A smarter way to borrow through your smartphones")]),_vm._m(0),_c('div',{staticClass:"item_download",on:{"click":function($event){return _vm.downloadApp()}}},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/Google-play.png"),"alt":""}})])]),_vm._m(1)]),_c('div',{staticClass:"inner inner1_5 flex"},[_vm._m(2),_c('img',{staticClass:"center2",attrs:{"src":require("@/assets/images/inner_1.5.png"),"alt":""}}),_c('div',{staticClass:"center3 flex fd-c jc-sb"},[_vm._m(3),_vm._m(4),_c('h2',{staticClass:"flex ai-c jc-c",on:{"click":function($event){return _vm.downloadApp()}}},[_vm._v("Apply now! "),_c('img',{attrs:{"src":require("@/assets/images/white_right.png"),"alt":""}})])])]),_vm._m(5),_vm._m(6),_vm._m(7)])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"item_info"},[_c('div',{staticClass:"info_tips"},[_c('p',[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/icon_item_tick.png"),"alt":""}}),_vm._v("No Collateral")]),_c('p',[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/icon_item_tick.png"),"alt":""}}),_vm._v("Safe and Secure loan")]),_c('p',[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/icon_item_tick.png"),"alt":""}}),_vm._v("Convenient and Hassle-free")])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"right_item"},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/top_app.png"),"alt":""}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"center1"},[_c('p',[_vm._v("Loan Amount")]),_c('h1',[_vm._v("₱ 30,000")]),_c('img',{attrs:{"src":require("@/assets/images/inner_1.5_left.png"),"alt":""}}),_c('div',[_c('div',{staticClass:"flex jc-sb"},[_c('p',[_vm._v("MIN")]),_c('p',[_vm._v("MAX")])]),_c('div',{staticClass:"flex jc-sb"},[_c('p',[_vm._v("₱ 1,000")]),_c('p',[_vm._v("₱ 30,000")])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/images/icon_item_tick.png"),"alt":""}}),_vm._v("Repayment Term "),_c('span',[_vm._v("91-180 days")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('p',[_c('img',{attrs:{"src":require("@/assets/images/icon_item_tick.png"),"alt":""}}),_vm._v("Annual interest rate "),_c('span',[_vm._v("10%-35%")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner inner2"},[_c('div',{staticClass:"left_item"},[_c('img',{attrs:{"src":require("@/assets/images/loanImg/icon_people.png"),"alt":""}})]),_c('div',{staticClass:"right_item"},[_c('h1',[_vm._v("What can PesoGo provide to you?")]),_c('div',{staticClass:"item_info"},[_c('div',{staticClass:"info_tips"},[_c('p',[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/app-icon-1.png"),"alt":""}}),_vm._v("24-hour loan service, you can apply any time.")]),_c('p',[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/app-icon-2.png"),"alt":""}}),_vm._v("No Collateral.")]),_c('p',[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/app-icon-3.png"),"alt":""}}),_vm._v("Variety of methods to receive money.")]),_c('p',[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/app-icon-4.png"),"alt":""}}),_vm._v("Flexible loan amount and duration.")]),_c('p',[_c('img',{staticClass:"info_img1",attrs:{"src":require("@/assets/images/loanImg/app-icon-5.png"),"alt":""}}),_vm._v("Relatively low fees.")])])])])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner inner3"},[_c('h3',[_vm._v("How to borrow money in PesoGo?")]),_c('img',{attrs:{"src":require("@/assets/images/loanImg/inner3.png")}})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"inner inner5"},[_c('div',{staticClass:"inner5-bg"},[_c('h3',[_vm._v("How To Contact Us")])]),_c('div',{staticClass:"inner5_item"},[_c('div',{staticClass:"content"},[_c('div',{staticClass:"left_item"},[_c('div',{staticClass:"item_info"},[_c('p',[_vm._v("170 TORRE VENEZIA SUITES TIMOG AVE. COR. SCT. SANTIAGO ST. LAGING HANDA")])]),_c('img',{attrs:{"src":require("@/assets/images/loanImg/home-inner5-1.png"),"alt":""}})])]),_c('div',{staticClass:"content"},[_c('div',{staticClass:"left_item"},[_c('div',{staticClass:"item_info"},[_c('p',[_vm._v("help@pesogoapp.com")])]),_c('img',{attrs:{"src":require("@/assets/images/loanImg/home-inner5-2.png"),"alt":""}})])])])])
}]

export { render, staticRenderFns }